import React, { useCallback, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PhoneIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
} from "@100mslive/react-icons";
import { Box, Dialog, Flex, IconButton } from "@100mslive/roomkit-react";
import { useAuth } from "../../contexts/Auth";
import { DialogContent, DialogRow } from "../../primitives/DialogContent";
import {
  onMessageListener,
  requestForToken,
} from "../../services/firebaseService";

// export const audio = new Audio("/call.mp3");
// export const playSound = () => {
//   audio.play();
// };
// export const stopSound = () => {
//   audio.pause();
//   audio.currentTime = 0;
// };

const Notification = () => {
  const { setTourData, setRenderVirtualTour, setRenderSchedule } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [audio] = useState(new Audio("/call.mp3"));
  const [data = {}, setData] = useState({});
  const [showCallScreen, setShowCallScreen] = useState(false);
  const notify = () => toast(<ToastDisplay />);
  const { notification = {}, data: notifyData = {} } = data;
  const {
    action = "",
    telephone = "",
    customer_name = "",
    title: titleFromData = "",
  } = notifyData;
  let { title = "", body: bodyMessage = "" } = notification;

  if (["new-call", "schedule-new-call", "join-call"].includes(action)) {
    title = `${customer_name} calling...`;
    bodyMessage = titleFromData;
  } else if (action === "end-call") {
    title = titleFromData;
    bodyMessage = titleFromData;
  }

  const playSound = useCallback(() => {
    audio.play();
  }, [audio]);
  const stopSound = useCallback(() => {
    audio.pause();
    audio.currentTime = 0;
  }, [audio]);

  function ToastDisplay() {
    return (
      <div>
        <p style={{ margin: 0 }}>
          <b>{title}</b>
        </p>
        <p style={{ margin: 0 }}>{bodyMessage}</p>
      </div>
    );
  }

  const handleReject = useCallback(() => {
    stopSound();
    setShowCallScreen(false);
    setData({});
  }, [stopSound, setShowCallScreen, setData]);

  const handleNotification = useCallback(
    notification => {
      setRenderVirtualTour(i => i + 1);
      const { data = {} } = notification;
      const { action = "" } = data;
      if (["new-call", "schedule-new-call", "join-call"].includes(action)) {
        playSound();
        setShowCallScreen(true);
        setTimeout(() => {
          handleReject();
        }, 45000);
      } else if (action === "schedule-notification") {
        setRenderSchedule(i => i + 1);
      } else if (action === "attended-notification" || action === "end-call") {
        handleReject();
      }
    },
    [setRenderVirtualTour, setRenderSchedule, playSound, handleReject]
  );

  useEffect(() => {
    if (navigator.serviceWorker) {
      // navigator.serviceWorker.register("sw.js");
      navigator.serviceWorker.ready.then(registration => {
        registration.active.postMessage("Hi service worker");
      });

      // Listen for messages from the service worker
      navigator.serviceWorker.addEventListener("message", event => {
        setData(event.data);
        handleNotification(event.data);
        console.log(
          "Main window received a message from the service worker:",
          event.data
        );
      });
    }
  }, [handleNotification]);

  useEffect(() => {
    if (title) {
      notify();
    }
  }, [title]);

  useEffect(() => {
    requestForToken();
    onMessageListener()
      .then(payload => {
        setData(payload);
        handleNotification(payload);
      })
      .catch(err => console.log("failed: ", err));
  }, [handleNotification]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (location?.pathname !== "/") {
        window.onbeforeunload = function () {
          return "You have unsaved changes!";
        };
      } else {
        window.onbeforeunload = null;
      }
    }
  }, [location]);

  const handleAccept = () => {
    setTourData(notifyData);
    stopSound();
    const { room_id, host_token } = notifyData;
    navigate(`/preview/${room_id}/salesrep?auth_token=${host_token}`);
    setShowCallScreen(false);
    setData({});
  };

  return (
    <>
      <Toaster />
      <Dialog.Root open={showCallScreen} modal={false}>
        <DialogContent
          className="call-notification-dialog"
          closeable={false}
          title="Incoming call"
          Icon={PhoneIcon}
        >
          <Flex align="center" justify="center" direction="column">
            <Box css={{ margin: "$10" }}>{`${customer_name} Calling`}</Box>
            <Box css={{ marginBottom: "$10" }}>{telephone}</Box>
          </Flex>
          <DialogRow justify="center" css={{ gridGap: 15 }}>
            <IconButton
              className="call-action-button reject"
              onClick={e => {
                console.log(e);
                handleReject();
              }}
            >
              <ThumbsDownIcon />
              Reject
            </IconButton>
            <IconButton
              className="call-action-button accept"
              onClick={e => {
                console.log(e);
                handleAccept();
              }}
            >
              <ThumbsUpIcon />
              Accept
            </IconButton>
          </DialogRow>
        </DialogContent>
      </Dialog.Root>
    </>
  );
};

export default Notification;
